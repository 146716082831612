/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * pulte.com
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2015
 */

/* Light
 *
 */
 @font-face {
    font-family: "StagSans";
    src:url("/assets/divosta/fonts/StagSans-Light-Web.eot");
    src:url("/assets/divosta/fonts/StagSans-Light-Web.eot?#iefix") format("embedded-opentype"),
        url("/assets/divosta/fonts/StagSans-Light-Web.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

/* Light Italic
 *
 * How to use:
 * .class {
 *      font-style: italic;
 * }
 */
@font-face {
    font-family: "StagSans";
    src:url("/assets/divosta/fonts/StagSans-LightItalic-Web.eot");
    src:url("/assets/divosta/fonts/StagSans-LightItalic-Web.eot?#iefix") format("embedded-opentype"),
        url("/assets/divosta/fonts/StagSans-LightItalic-Web.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}


/* Regular
 *
 * How to use:
 * .class {
 *      font-weight: 700;
 * }
 */
@font-face {
    font-family: "StagSans";
    src:url("/assets/divosta/fonts/StagSans-Book-Web.eot");
    src:url("/assets/divosta/fonts/StagSans-Book-Web.eot?#iefix") format("embedded-opentype"),
        url("/assets/divosta/fonts/StagSans-Book-Web.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

/* Regular Italic
 *
 * How to use:
 * .class {
 *      font-weight: 700;
 *      font-style: italic;
 * }
 */
@font-face {
    font-family: "StagSans";
    src:url("/assets/divosta/fonts/StagSans-BookItalic-Web.eot");
    src:url("/assets/divosta/fonts/StagSans-BookItalic-Web.eot?#iefix") format("embedded-opentype"),
        url("/assets/divosta/fonts/StagSans-BookItalic-Web.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}

/* Bold
 *
 * How to use:
 * .class {
 *      font-weight: 900;
 * }
 */
@font-face {
    font-family: "StagSans";
    src:url("/assets/divosta/fonts/StagSans-SemiBold-Web.eot");
    src:url("/assets/divosta/fonts/StagSans-SemiBold-Web.eot?#iefix") format("embedded-opentype"),
        url("/assets/divosta/fonts/StagSans-SemiBold-Web.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

/* Bold Italic
 *
 * How to use:
 * .class {
 *      font-weight: 900;
 *      font-style: italic;
 * }
 */
@font-face {
    font-family: "Brown-Pro-Light";
    font-family: "StagSans";
    src:url("/assets/divosta/fonts/StagSans-SemiBoldItalic-Web.eot");
    src:url("/assets/divosta/fonts/StagSans-SemiBoldItalic-Web.eot?#iefix") format("embedded-opentype"),
        url("/assets/divosta/fonts/StagSans-SemiBoldItalic-Web.woff") format("woff");
    font-weight: 900;
    font-style: italic;
}
  // These classes are overriding styles from the simple-keyboard library

  .simple-keyboard.hg-theme-ios {
    width: 100%;
    margin: auto;
  }

  .simple-keyboard.hg-theme-ios .hg-row .hg-button {
    flex-grow: 1;
    cursor: pointer;
  }

  .simple-keyboard.hg-theme-ios .hg-row {
    display: flex;
  }

  .simple-keyboard.hg-theme-ios .hg-row:not(:last-child) {
    margin-bottom: 5px;
  }

  .simple-keyboard.hg-theme-ios .hg-row:nth-child(3) {
    margin-left: 5%;
  }

  .simple-keyboard.hg-theme-ios {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .simple-keyboard.hg-theme-ios .hg-button {
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0;
    color: #fdfdfd;
    background: #5b5b5b;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    font-weight: 500;
    font-size: 1.25rem;
    max-width: 8.6%;
    min-width: 50px;
    min-height: 50px;
    margin-right: 1%;
  }

  .simple-keyboard.hg-theme-ios .hg-row .hg-button:last-child {
    margin-right: 0;
  }

  .simple-keyboard.hg-theme-ios .hg-button:active,
  .simple-keyboard.hg-theme-ios .hg-button:focus {
    background: #e4e4e4;
  }

  .simple-keyboard.hg-theme-ios .hg-button.hg-button-space,
  .simple-keyboard.hg-theme-ios .hg-button.hg-button-shift,
  .simple-keyboard.hg-theme-ios .hg-button.hg-button-shiftactivated {
    background-color: #5b5b5b;
  }

  .simple-keyboard.hg-theme-ios .hg-button-send,
  .simple-keyboard.hg-theme-ios .hg-button-space {
    max-width: 25%;
    min-width: 25%;
  }

  .simple-keyboard.hg-theme-ios .hg-button-dark {
    background-color: #3a3a3a;
  }

  .simple-keyboard.hg-theme-ios .hg-button-medium {
    min-width: 11.5%;
    max-width: 11.5%;
  }

  .simple-keyboard.hg-theme-ios .hg-button-shift,
  .simple-keyboard.hg-theme-ios .hg-button-shiftactivated {
    margin-right: 3.6%;
  }

  .simple-keyboard.hg-theme-ios .hg-button-bksp {
    margin-left: 3.1%;
  }

  .simple-keyboard.hg-theme-ios .hg-button-suggestion {
    min-width: 33%;
    max-width: 33%;
    margin-bottom: 1.5%;
    border-radius: 0;
    margin-right: 0.5%;
    background: #3b3b3b;
  }

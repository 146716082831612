/*
 * Global Mixins
 *
 */

// Hover
//
// Overrides bootstrap mixin for hover
@mixin hover {
    // only attach hover styles if the device being used supports it
    @media (hover: hover) {
        &:hover,
        &:active,
        &:focus
        &:visited {
            @content;
        }
    }
}


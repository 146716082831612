.LmpTooltip {

  &__button {
    padding: 0px 8px;
  }

  &__body {
    width: 20%;
    z-index: 2020;
  }

  &__header {
    font-size: 1.2rem;
  }

  &__close-button {
    font-size: 4.5rem;
    line-height: 3rem;
    top: 0;
    right: 0;
  }

  &__divider {
    border-top: 1px solid hsla(0,0%,100%,.3);
  }

  &__content {
    font-size: 1rem;
    background-color: var(--brand-primary);
    color: var(--brand-white);
  }

  &__disclaimer {
    font-size: 0.75rem;
  }
}

.SpecialOffersCard__tooltip {
  &--button {
      font-style: normal;
      padding: 0px 7px;
      position: absolute;
      top: 2%;
      right: 2%;
  }

  &--content {
      background-color: var(--brand-primary);
      color: var(--brand-white);
      max-width: 25rem;
  }
}

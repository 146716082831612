/* You can add global styles to this file, and also import other style files */

@import "scss/core/core";

html, body {
  // Prevent element & text highlighting
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: var(--brand-page-background-color);
}

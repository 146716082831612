.Carousel {

  .carousel {

    &:focus {
      outline: 0;
    }

    &-control-next-icon,
    &-control-prev-icon {
      width: 70px;
      height: 100px;
      border-radius: 4px 0 0 4px;
      background-color: rgba(0, 0, 0, 0.6);
    }

    &-control-next-icon {
      background-image: url("/assets/global/icons/carousel-arrow-right-dark.png");
    }

    &-control-prev-icon {
      background-image: url("/assets/global/icons/carousel-arrow-left-dark.png");
    }

    &-control-prev-icon {
      border-radius: 0 4px 4px 0;
    }

    &--floor-plan {
      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        width: 40px;
        height: 70px;
      }
    }

    &-control-next {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }

    &-inner {
      border-radius: 4px;
    }


    &-control-prev {
      display: flex;
      justify-content: flex-start;
      cursor: pointer;
    }

    &-indicators li {
      height: 8px;
      width: 8px;
      color: transparent;
      border: 1px solid var(--gray-light);
      border-radius: 50%;
      margin: 15px;
      margin-bottom: 0;
      padding: 4px;
      list-style: disc inside;
      font-size: 1.5rem;
      line-height: 5px;
      text-indent: 0;
      transition: none;
    }

    &-indicators .active {
      color: var(--brand-primary);
    }

    &-caption {
      background-color: black;
      width: 100%;
      text-align: inherit;
      padding-left: 10px;
      left: 0;
      right: 0;
      position: initial
    }
  }

  &--content {

    .carousel {
      &-control-next,
      &-control-prev {
          width: 70px;
      }
    }

    app-content-card,
    app-box-card,
    app-special-offers-card,
    app-inline-video {
      width: 100%;

      .card__container--special-offers,
      .card__special-offers-image {
        height: 100%;
      }

      .BoxCard {
        height: 100%;
      }

      .card__container--box {
        width: 100%;
      }
    }
  }

  &--content-card {
    .carousel {

      &-control-next,
      &-control-prev {
        width: 15%;
        padding-bottom: 3rem;
      }

      &-control-next-icon,
      &-control-prev-icon {
        width: 80%;
        max-width: 105px;
        height: 25%;
        max-height: 150px;
      }
    }
  }

  &--small-arrows {
    .carousel {

      &-control-next-icon,
      &-control-prev-icon {
        width: 56.9%;
        height: 22.4%;
        margin-bottom: 0;
      }

      &-control-next-icon {
        background-image: url("/assets/global/icons/carousel-arrow-right-small.png");
      }

      &-control-prev-icon {
        background-image: url("/assets/global/icons/carousel-arrow-left-small.png");
      }
    }
  }

  &--elevated-arrows {
    .carousel {
      &-control-next-icon,
      &-control-prev-icon {
        margin-bottom: 64px;
      }
    }
  }
}

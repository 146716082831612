.brand-americanwest {
    // logo
    --logo-background: url("/assets/americanwest/logo.svg");
    --logo-width: 119px;
    --logo-height: 25px;

    // fonts
    --font-family: "Optima-LinoType", Helvetica, sans-serif;

    // colors
    --brand-primary: #1b4274;

    --brand-white: #fff;

    --brand-alt-white: #f2f2f2;

    --brand-page-background-color: #f6f6f6;

    --overlay-white: rgba(255, 255, 255, 0.9);
    --overlay-white-lighter: rgba(255, 255, 255, 0.7);
    --overlay-black: rgba(0, 0, 0, 0.9);
    --overlay-gray: rgba(131, 131, 131, 0.7);

    --gray-base: #000;
    --gray-darker: #222;
    --gray-dark: #333;
    --gray: #555;
    --gray-light: #777;
    --gray-lighter: #eee;
    --gray-custom-filter: #b1b1b1;

    --light-gold: #f3bd42;

    --greeny-grey: #7ea27c;

    --error-red: #ff0134;

    --text-color: #343434;
    --brand-header-text: #1a1a1a;

    // button colors and styles
    --btn-default-color: #1a1a1a;
    --btn-default-bg: #fff;
    --btn-default-border: var(--btn-default-color);

    --btn-primary-color: #fff;
    --btn-primary-bg: #255b7b;
    --btn-primary-border: #255b7b;
    --btn-primary-hover-bg: #255b7b;

    --btn-link-disabled-color: var(--gray-light);

    --btn-border-radius-base: 35px;

    --btn-link-color: #1b4274;

    // light link colors and styles
    --light-link: var(--brand-white);
    --light-link-hover: #b3b3b3;

    // interactions
    --interaction-inactive: #387fa8;
    --interaction-active: #06384e;

    --brand-toggle-background: #1b4274;
    --brand-toggle-foreground: #255b7b;
    --brand-toggle-active-text: #1b4274;
    --brand-toggle-inactive-text: var(--brand-toggle-foreground);

    // back/close button icons
    --brand-back-arrow: url("/assets/americanwest/back-arrow.svg");
    --brand-close-cross: url("/assets/americanwest/close-cross.svg");

    // modals
    --brand-modal-background: #fcfaf5;
    --brand-modal-title-text: #1a1a1a;

    --brand-gallery-toggle-arrow: #1a1a1a;
    --brand-gallery-toggle-inactive-text: #1b4274;

    --brand-down-arrow-icon: url("/assets/americanwest/dark-blue-down-arrow.svg");

    // map
    --brand-map-marker-icon: url("/assets/americanwest/dark-blue-map-marker.svg");
    --brand-map-marker-selected-icon: url("/assets/americanwest/dark-blue-map-marker-selected.svg");
    --brand-map-marker-home-icon: url("/assets/americanwest/dark-blue-map-marker-home.svg");
    --brand-map-marker-label-color: var(--brand-white);
    --brand-map-marker-label-font-size: 1.5;
    --brand-map-default-pin-color: #255b7b;
    --brand-map-selected-pin-color: #1b4274;

    // forms
    --brand-filter-icon: url("/assets/americanwest/dark-blue-filter.svg");
    --brand-checkmark-icon: url("/assets/americanwest/dark-blue-checkmark.svg");

    // lot map statuses
    --lot-map-color-available: #71ad77;
    --lot-map-color-qmi: #5aa9d9;
    --lot-map-color-model-available: #00476f;
    --lot-map-color-sold: #c31d1d;
    --lot-map-color-not-released: #989898;
  }

/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * pulte.com
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2015
 */

/* Regular
 *
 */
 @font-face {
    font-family: "Palatino-Linotype";
    src: url("/assets/jw/fonts/Palatino-Linotype-Regular.eot?#iefix");
    src: url("/assets/jw/fonts/Palatino-Linotype-Regularc.eot?#iefix") format("eot"),
    url("/assets/jw/fonts/Palatino-Linotype-Regular.woff2") format("woff2"),
    url("/assets/jw/fonts/Palatino-Linotype-Regular.woff") format("woff"),
    url("/assets/jw/fonts/Palatino-Linotype-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

/* Regular Italic
 *
 * How to use:
 * .class {
 *      font-style: italic;
 * }
 */
 
 @font-face {
    font-family: "Palatino-Linotype";
    src: url("/assets/jw/fonts/Palatino-Linotype-Italic.eot?#iefix");
    src: url("/assets/jw/fonts/Palatino-Linotype-Italic.eot?#iefix") format("eot"),
         url("/assets/jw/fonts/Palatino-Linotype-Italic.woff2") format("woff2"),
         url("/assets/jw/fonts/Palatino-Linotype-Italic.woff") format("woff"),
         url("/assets/jw/fonts/Palatino-Linotype-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}


/* Bold
 *
 * How to use:
 * .class {
 *      font-weight: 700;
 * }
 */
@font-face {
    font-family: "Palatino-Linotype";
    src: url("/assets/jw/fonts/Palatino-Linotype-Bold.eot?#iefix");
    src: url("/assets/jw/fonts/Palatino-Linotype-Bold.eot?#iefix") format("eot"),
         url("/assets/jw/fonts/Palatino-Linotype-Bold.woff2") format("woff2"),
         url("/assets/jw/fonts/Palatino-Linotype-Bold.woff") format("woff"),
         url("/assets/jw/fonts/Palatino-Linotype-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

/* Bold Italic
 *
 * How to use:
 * .class {
 *      font-weight: 700;
 *      font-style: italic;
 * }
 */
@font-face {
    font-family: "Palatino-Linotype";
    src: url("/assets/jw/fonts/Palatino-Linotype-BoldItalic.eot?#iefix");
    src: url("/assets/jw/fonts/Palatino-Linotype-BoldItalic.eot?#iefix") format("eot"),
         url("/assets/jw/fonts/Palatino-Linotype-BoldItalic.woff2") format("woff2"),
         url("/assets/jw/fonts/Palatino-Linotype-BoldItalic.woff") format("woff"),
         url("/assets/jw/fonts/Palatino-Linotype-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;     
}


/* Bolder
 *
 * How to use:
 * .class {
 *      font-weight: 900;
 * }
 */
@font-face {
    font-family: "Palatino-Linotype";
    src: url("/assets/jw/fonts/Palatino-Linotype-Bold.eot?#iefix");
    src: url("/assets/jw/fonts/Palatino-Linotype-Bold.eot?#iefix") format("eot"),
         url("/assets/jw/fonts/Palatino-Linotype-Bold.woff2") format("woff2"),
         url("/assets/jw/fonts/Palatino-Linotype-Bold.woff") format("woff"),
         url("/assets/jw/fonts/Palatino-Linotype-Bold.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

/* Bolder Italic
 *
 * How to use:
 * .class {
 *      font-weight: 900;
 *      font-style: italic;
 * }
 */
@font-face {
    font-family: "Palatino-Linotype";
    src: url("/assets/jw/fonts/Palatino-Linotype-BoldItalic.eot?#iefix");
    src: url("/assets/jw/fonts/Palatino-Linotype-BoldItalic.eot?#iefix") format("eot"),
         url("/assets/jw/fonts/Palatino-Linotype-BoldItalic.woff2") format("woff2"),
         url("/assets/jw/fonts/Palatino-Linotype-BoldItalic.woff") format("woff"),
         url("/assets/jw/fonts/Palatino-Linotype-BoldItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;     
}
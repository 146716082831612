/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * pulte.com
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2015
 */

/* Regular
 *
 */
 @font-face {
    font-family: "Brown-Pro-Light";
    src:url("/assets/pulte/fonts/lineto-brown-pro-light.eot");
    src:url("/assets/pulte/fonts/lineto-brown-pro-light.eot?#iefix") format("embedded-opentype"),
        url("/assets/pulte/fonts/lineto-brown-pro-light.woff") format("woff"),
        url("/assets/pulte/fonts/lineto-brown-pro-light.ttf") format("truetype"),
        url("/assets/pulte/fonts/lineto-brown-pro-light.svg#lineto-brown-pro-light") format("svg");
    font-weight: normal;
    font-style: normal;
}

/* Regular Italic
 *
 * How to use:
 * .class {
 *      font-style: italic;
 * }
 */
@font-face {
    font-family: "Brown-Pro-Light";
    src:url("/assets/pulte/fonts/lineto-brown-pro-lightItalic.eot");
    src:url("/assets/pulte/fonts/lineto-brown-pro-lightItalic.eot?#iefix") format("embedded-opentype"),
        url("/assets/pulte/fonts/lineto-brown-pro-lightItalic.woff") format("woff"),
        url("/assets/pulte/fonts/lineto-brown-pro-lightItalic.ttf") format("truetype"),
        url("/assets/pulte/fonts/lineto-brown-pro-lightItalic.svg#lineto-brown-pro-light") format("svg");
    font-weight: normal;
    font-style: italic;
}


/* Bold
 *
 * How to use:
 * .class {
 *      font-weight: 700;
 * }
 */
@font-face {
    font-family: "Brown-Pro-Light";
    src:url("/assets/pulte/fonts/lineto-brown-pro-regular.eot");
    src:url("/assets/pulte/fonts/lineto-brown-pro-regular.eot?#iefix") format("embedded-opentype"),
        url("/assets/pulte/fonts/lineto-brown-pro-regular.woff") format("woff"),
        url("/assets/pulte/fonts/lineto-brown-pro-regular.ttf") format("truetype"),
        url("/assets/pulte/fonts/lineto-brown-pro-regular.svg#lineto-brown-pro-light") format("svg");
    font-weight: 700;
    font-style: normal;
}

/* Bold Italic
 *
 * How to use:
 * .class {
 *      font-weight: 700;
 *      font-style: italic;
 * }
 */
@font-face {
    font-family: "Brown-Pro-Light";
    src:url("/assets/pulte/fonts/lineto-brown-pro-Italic.eot");
    src:url("/assets/pulte/fonts/lineto-brown-pro-Italic.eot?#iefix") format("embedded-opentype"),
        url("/assets/pulte/fonts/lineto-brown-pro-Italic.woff") format("woff"),
        url("/assets/pulte/fonts/lineto-brown-pro-Italic.ttf") format("truetype"),
        url("/assets/pulte/fonts/lineto-brown-pro-Italic.svg#lineto-brown-pro-light") format("svg");
    font-weight: 700;
    font-style: italic;
}

/* Bolder
 *
 * How to use:
 * .class {
 *      font-weight: 900;
 * }
 */
@font-face {
    font-family: "Brown-Pro-Light";
    src:url("/assets/pulte/fonts/lineto-brown-pro-bold.eot");
    src:url("/assets/pulte/fonts/lineto-brown-pro-bold.eot?#iefix") format("embedded-opentype"),
        url("/assets/pulte/fonts/lineto-brown-pro-bold.woff") format("woff"),
        url("/assets/pulte/fonts/lineto-brown-pro-bold.ttf") format("truetype"),
        url("/assets/pulte/fonts/lineto-brown-pro-bold.svg#lineto-brown-pro-light") format("svg");
    font-weight: 900;
    font-style: normal;
}

/* Bolder Italic
 *
 * How to use:
 * .class {
 *      font-weight: 900;
 *      font-style: italic;
 * }
 */
@font-face {
    font-family: "Brown-Pro-Light";
    src:url("/assets/pulte/fonts/lineto-brown-pro-boldItalic.eot");
    src:url("/assets/pulte/fonts/lineto-brown-pro-boldItalic.eot?#iefix") format("embedded-opentype"),
        url("/assets/pulte/fonts/lineto-brown-pro-boldItalic.woff") format("woff"),
        url("/assets/pulte/fonts/lineto-brown-pro-boldItalic.ttf") format("truetype"),
        url("/assets/pulte/fonts/lineto-brown-pro-boldItalic.svg#lineto-brown-pro-light") format("svg");
    font-weight: 900;
    font-style: italic;
}
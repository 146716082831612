/*
 * Global Button Styles
 *
 */

.btn {
    padding: 0px 20px;
    height: 60px;
    min-width: 130px;
    font-size: 1.5rem;
    letter-spacing: 0.4px;
    margin-right: 10px;
    border-radius: var(--btn-border-radius-base);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:last-of-type{
        margin-right: 0;
    }

    @include transition(background-color 150ms ease-in-out);

    @include hover {
        @include transition(background-color 150ms ease-in-out);
    }
}

.btn.disabled,
.btn[disabled] {
    opacity: 1;
    background-color: var(--gray-lighter);
    color: var(--text-color);
    cursor: not-allowed;
}

.btn-primary {
    color: var(--btn-primary-color);
    background-color: var(--btn-primary-bg);
    border: 1px solid var(--btn-primary-bg);

    @include hover {
        background-color: var(--btn-primary-hover-bg) !important;
    }
}

.btn-primary:focus {
  background-color: var(--btn-primary-bg);
}

.btn-default {
    color: var(--btn-default-color);
    font-weight: 600;
    background-color: var(--btn-default-bg);
    border: 2px solid var(--btn-default-border);
    transition: background-color 0ms ease;

    @include hover {
        opacity: 0.8;
    }
}

.btn-default:focus {
  background-color: var(--btn-default-bg);
}

.btn-primary.disabled,
.btn-primary[disabled] {
    border-color: var(--btn-primary-border);
}

.btn-card {
    padding: 15px 55px;
    font-size: 1.5rem;
}

.btn-i {
    border-radius: 50%;
    border: none;
    padding: 0 7px;
    font-size: 0.775rem;
    // !important is used to override focus/active states, that after leaving the modal the button will have shadows/colors that don't match the comp or main site.
    background-color: var(--gray-custom-filter);
    color: var(--brand-white) !important;
    box-shadow: none !important;
    outline: none !important;
    &:focus {
        background-color: var(--brand-primary);
    }
}

.btn-link {
  color: var(--btn-link-color);
}

.btn-dropdown {
  height: 50px;
  min-width: 200px;
  font-size: 1.2rem;
  color: var(--gray);
  border-radius: 6px;
  border: solid 1px var(--gray-light);
  background-color: var(--brand-white);
}

.btn-dropdown:focus {
  box-shadow: none;
}

.back-wrapper {
  position: absolute;
  color: var(--btn-primary-bg);
  top: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
  line-height: 1;
  font-size: 1.5625rem;
  font-weight: bold;
  float: left;

  &-btn {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

.card {
    padding: 0 20px 20px 20px;

    &__container {
        overflow: hidden;
        border-radius: 5px;
        background-color: var(--brand-white);
        box-shadow: 1px 1px 5px;
        max-width: fit-content;

        &--series {
            display: flex;
            flex-flow: column;
            max-width: none;
        }

        &--article {
            padding-left: 15%;
            padding-right: 15%;
        }

        &--box {
            box-shadow: 1px 1px 5px;
            width: 33.3%;
            min-width: 320px;
        }

        &--special-offers {
            box-shadow: 1px 1px 5px;

            &--no-offers {
                color: var(--brand-primary);
                padding-left: 13%;
                padding-right: 13%;
                margin-top: 5%;
            }
        }

        &--tile {
          width: 16.7%;
          min-width: 320px;
          height: 36.6%;
          min-height: 340px;
        }
    }

    &__header {
        text-align: center;
        color: var(--btn-primary-bg);
        font-weight: 100;
        margin: 10px 0 0 0;
        line-height: 1;

        &--article,
        &--content,
        &--box {
            color: var(--brand-primary);
        }

        &--series {
            margin: 20px 0;
        }
    }

    &__grid-container {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
    }

    &__grid-item {
        text-align: center;
        padding-bottom: 10px;
        height: 3.75rem;

        &--price {
            min-height:11vh;
        }

        &--series {
            display: flex;
            font-style: normal;
            text-align: center;
            margin-bottom: 10px;
        }
    }

    &__grid-item-address {
        text-align: center;
        padding-bottom: 10px;
        font-size: 1.1rem;
    }

    &__grid-item-series {
        text-align: center;
        margin-bottom: 10px;
        color: var(--gray-light);
        font-style: italic;
    }

    &__grid-item-value {
        font-size: 1.22rem;
    }

    &__grid-item-value-lmp {
      font-size: 1.12rem;
    }

    &__grid-item-value-price {
        font-size: 1.5rem;
        &--final {
            &-line {
                text-decoration: line-through;
                font-size: 1rem;
            }
          &-color {
              color:var(--error-red);
          }
        }
    }

    &__grid-item-text-price {
        color: var(--gray-light);
        padding-bottom: 10px;
    }

    &__grid-item-text {
        color: var(--gray-light);
        line-height: 1;
    }

    &__button-container {
        text-align: center;
        padding: 15px 0 20px 0;

        &--series {
            color:white;
        }
    }
}

.ContentContainer {
  app-content-card,
  app-inline-video,
  app-special-offers-card,
  app-box-card {
    width: 100%;
  }

  .card__container--special-offers,
  .card__special-offers-image {
    height: 100%;
  }

  .BoxCard {
    height: 100%;
  }

  .card__container--box {
    width: 100%;
  }
}

/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * pulte.com
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2015
 */

/* Light
 *
 */
 @font-face {
    font-family: "MuseoSans";
    src:url("/assets/delwebb/fonts/museo-sans-light.eot");
    src:url("/assets/delwebb/fonts/museo-sans-light.eot?#iefix") format("embedded-opentype"),
        url("/assets/delwebb/fonts/museo-sans-light.woff2") format("woff2"),
        url("/assets/delwebb/fonts/museo-sans-light.woff") format("woff"),
        url("/assets/delwebb/fonts/museo-sans-light.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

/* Light Italic
 *
 * How to use:
 * .class {
 *      font-style: italic;
 * }
 */
@font-face {
    font-family: "MuseoSans";
    src:url("/assets/delwebb/fonts/museo-sans-light-italic.eot");
    src:url("/assets/delwebb/fonts/museo-sans-light-italic.eot?#iefix") format("embedded-opentype"),
        url("/assets/delwebb/fonts/museo-sans-light-italic.woff2") format("woff2"),
        url("/assets/delwebb/fonts/museo-sans-light-italic.woff") format("woff"),
        url("/assets/delwebb/fonts/museo-sans-light-italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}


/* Regular
 *
 * How to use:
 * .class {
 *      font-weight: 700;
 * }
 */
@font-face {
    font-family: "MuseoSans";
    src:url("/assets/delwebb/fonts/museo-sans-regular.eot");
    src:url("/assets/delwebb/fonts/museo-sans-regular.eot?#iefix") format("embedded-opentype"),
        url("/assets/delwebb/fonts/museo-sans-regular.woff2") format("woff2"),
        url("/assets/delwebb/fonts/museo-sans-regular.woff") format("woff"),
        url("/assets/delwebb/fonts/museo-sans-regular.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

/* Regular Italic
 *
 * How to use:
 * .class {
 *      font-weight: 700;
 *      font-style: italic;
 * }
 */
@font-face {
    font-family: "MuseoSans";
    src:url("/assets/delwebb/fonts/museo-sans-regular-italic.eot");
    src:url("/assets/delwebb/fonts/museo-sans-regular-italic.eot?#iefix") format("embedded-opentype"),
        url("/assets/delwebb/fonts/museo-sans-regular-italic.woff2") format("woff2"),
        url("/assets/delwebb/fonts/museo-sans-regular-italic.woff") format("woff"),
        url("/assets/delwebb/fonts/museo-sans-regular-italic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}

/* Bold
 *
 * How to use:
 * .class {
 *      font-weight: 900;
 * }
 */
@font-face {
    font-family: "MuseoSans";
    src:url("/assets/delwebb/fonts/museo-sans-bold.eot");
    src:url("/assets/delwebb/fonts/museo-sans-bold.eot?#iefix") format("embedded-opentype"),
        url("/assets/delwebb/fonts/museo-sans-bold.woff2") format("woff2"),
        url("/assets/delwebb/fonts/museo-sans-bold.woff") format("woff"),
        url("/assets/delwebb/fonts/museo-sans-bold.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

/* Bold Italic
 *
 * How to use:
 * .class {
 *      font-weight: 900;
 *      font-style: italic;
 * }
 */
@font-face {
    font-family: "MuseoSans";
    src:url("/assets/delwebb/fonts/museo-sans-bold-italic.eot");
    src:url("/assets/delwebb/fonts/museo-sans-bold-italic.eot?#iefix") format("embedded-opentype"),
        url("/assets/delwebb/fonts/museo-sans-bold-italic.woff2") format("woff2"),
        url("/assets/delwebb/fonts/museo-sans-bold-italic.woff") format("woff"),
        url("/assets/delwebb/fonts/museo-sans-bold-italic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}
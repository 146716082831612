.modal--full {
  height: var(--only-navbar);

  &.modal {
    overflow: hidden;
  }

  .modal- {
    &dialog {
      min-width: 100%;
      margin: 0;
      height: 100%;
    }

    &content {
      border: none;
      border-radius: 0;
      height: 100%;
      background-color: var(--brand-modal-background);
    }

    &title {
      font-size: 2.5rem;
      color: var(--brand-modal-title-text);
    }

    &header {
      border-bottom: none;
      align-items: center;
    }

    &footer {
      height: var(--product-summary-height);
      border-top: none;
      top: auto;
      padding: 0 0 0.25rem 0.75rem;
    }

    /* custom */
    &action {
      cursor: pointer;
      color: var(--btn-primary-bg);
      font-weight: bold;
      line-height: 1;
      font-size: 1.5625rem;
    }
  }
}

.modal--summary {
  height: var(--has-summary);

  .modal-footer {
    display: none;
  }
}

.modal--backdrop__nav-bar {
  height:  var(--only-navbar);
  background-color: var(--brand-white);
  opacity: 0.6 !important;
}

.modal--left {
  .modal-content {
    float: left;
    width: 33%;
    background-color: var(--overlay-black);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
  }
}

.modal--right {
  .modal-content {
    float: right;
    width: 33%;
    background-color: var(--overlay-black);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
  }
}

.modal--white-background {
  .modal-content {
    background-color: var(--brand-white);
  }
}

::-webkit-scrollbar {
  width: 50px;
}

::-webkit-scrollbar-button {
  height: 50px;
  background-image: url("/assets/Scroll-bar-up.svg");
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  background-color: transparent;
}

::-webkit-scrollbar-button:end {
  background-image: url("/assets/Scroll-bar-down.svg");
}

::-webkit-scrollbar-track {
  background-color: var(--gray-lighter);
  border-left: 23px solid transparent;
  border-right: 24px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb {
  background-color: var(--gray-light);
  border-left: 23px solid transparent;
  border-right: 24px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--gray);
}

.mini-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.mini-scrollbar::-webkit-scrollbar-track,
.mini-scrollbar::-webkit-scrollbar-thumb {
  border-left: 2px solid transparent;
  border-right: 4px solid transparent;
}

.mini-scrollbar::-webkit-scrollbar-button {
  height: 10px;
  background: transparent;
}

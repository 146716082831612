// Set the font family on the body to the css custom property that is mapped per brand
body {
    font-family: var(--font-family);
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.85rem;

}
h3 {
    font-size: 1.65rem;

}
h4 {
    font-size: 1.5rem;

}
h5 {
    font-size: 1.35rem;

}
h6 {
    font-size: 1.25rem;
}
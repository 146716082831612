.brand-delwebb {
  // logo
  --logo-background: url("/assets/delwebb/logo.svg");
  --logo-width: 130px;
  --logo-height: 28px;

  // fonts
  --font-family: "MuseoSans", Helvetica, sans-serif;

  // colors
  --brand-primary: #41b9db;

  --brand-white: #fff;

  --brand-page-background-color: #f6f6f6;

  --overlay-white: rgba(255, 255, 255, 0.9);
  --overlay-white-lighter: rgba(255, 255, 255, 0.7);
  --overlay-black: rgba(0, 0, 0, 0.9);
  --overlay-gray: rgba(131, 131, 131, 0.7);

  --gray-base: #000;
  --gray-darker: #222;
  --gray-dark: #333;
  --gray: #555;
  --gray-light: #777;
  --gray-lighter: #eee;
  --gray-custom-filter: #b1b1b1;

  --light-gold: #f3bd42;

  --greeny-grey: #7ea27c;

  --error-red: #ff0134;

  --text-color: #343434;
  --brand-header-text: #1a1a1a;

  // button colors and styles
  --btn-default-color: #1a1a1a;
  --btn-default-bg: #fff;
  --btn-default-border: var(--btn-default-color);

  --btn-primary-color: #fff;
  --btn-primary-bg: #f89d32;
  --btn-primary-border: #f79119;
  --btn-primary-hover-bg: rgba(248, 157, 50, 0.8);

  --btn-link-disabled-color: var(--gray-light);

  --btn-border-radius-base: 35px;

  --btn-link-color: #40b9da;

  // light link colors and styles
  --light-link: var(--brand-white);
  --light-link-hover: #b3b3b3;

  // interactions
  --interaction-inactive: #387fa8;
  --interaction-active: #06384e;

  --brand-toggle-background: #bbbcbc;
  --brand-toggle-foreground: #40b9da;
  --brand-toggle-active-text: #333333;
  --brand-toggle-inactive-text: var(--brand-toggle-foreground);

  // back/close button icons
  --brand-back-arrow: url("/assets/delwebb/back-arrow.svg");
  --brand-close-cross: url("/assets/delwebb/close-cross.svg");

  // modals
  --brand-modal-background: #f6f6f6;
  --brand-modal-title-text: #1a1a1a;

  --brand-gallery-toggle-arrow: #1a1a1a;
  --brand-gallery-toggle-inactive-text: #40b9da;

  --brand-down-arrow-icon: url("/assets/delwebb/black-down-arrow.svg");

  // map
  --brand-map-marker-icon: url("/assets/delwebb/light-blue-map-marker.svg");
  --brand-map-marker-selected-icon: url("/assets/delwebb/light-blue-map-marker-selected.svg");
  --brand-map-marker-home-icon: url("/assets/delwebb/light-blue-map-marker-home.svg");
  --brand-map-marker-label-color: var(--brand-white);
  --brand-map-marker-label-font-size: 1.5;
  --brand-map-default-pin-color: #40B9DA;
  --brand-map-selected-pin-color: #114959;

  // forms
  --brand-filter-icon: url("/assets/delwebb/light-blue-filter.svg");
  --brand-checkmark-icon: url("/assets/delwebb/black-checkmark.svg");

  // lot map statuses
  --lot-map-color-available: #71ad77;
  --lot-map-color-qmi: #5aa9d9;
  --lot-map-color-model-available: #00476f;
  --lot-map-color-sold: #c31d1d;
  --lot-map-color-not-released: #989898;
}

/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * pulte.com
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2015
 */

/* Light
 *
 */
 @font-face {
    font-family: "Apercu";
    src:url("/assets/centex/fonts/apercu-light.eot");
    src:url("/assets/centex/fonts/apercu-light.eot?#iefix") format("embedded-opentype"),
        url("/assets/centex/fonts/apercu-light.woff2") format("woff2"),
        url("/assets/centex/fonts/apercu-light.woff") format("woff"),
        url("/assets/centex/fonts/apercu-light.ttf") format("truetype"),
        url("/assets/centex/fonts/apercu-light.svg#aperculight") format("svg");
    font-weight: normal;
    font-style: normal;
}

/* Light Italic
 *
 * How to use:
 * .class {
 *      font-style: italic;
 * }
 */
@font-face {
    font-family: "Apercu";
    src:url("/assets/centex/fonts/apercu-light.eot");
    src:url("/assets/centex/fonts/apercu-light.eot?#iefix") format("embedded-opentype"),
        url("/assets/centex/fonts/apercu-light.woff2") format("woff2"),
        url("/assets/centex/fonts/apercu-light.woff") format("woff"),
        url("/assets/centex/fonts/apercu-light.ttf") format("truetype"),
        url("/assets/centex/fonts/apercu-light.svg#aperculight") format("svg");
    font-weight: normal;
    font-style: italic;
}


/* Regular
 *
 * How to use:
 * .class {
 *      font-weight: 700;
 * }
 */
@font-face {
    font-family: "Apercu";
    src:url("/assets/centex/fonts/apercu-regular.eot");
    src:url("/assets/centex/fonts/apercu-regular.eot?#iefix") format("embedded-opentype"),
        url("/assets/centex/fonts/apercu-regular.woff2") format("woff2"),
        url("/assets/centex/fonts/apercu-regular.woff") format("woff"),
        url("/assets/centex/fonts/apercu-regular.ttf") format("truetype"),
        url("/assets/centex/fonts/apercu-regular.svg#apercuregular") format("svg");
    font-weight: 700;
    font-style: normal;
}

/* Regular Italic
 *
 * How to use:
 * .class {
 *      font-weight: 700;
 *      font-style: italic;
 * }
 */
@font-face {
    font-family: "Apercu";
    src:url("/assets/centex/fonts/apercu-regular.eot");
    src:url("/assets/centex/fonts/apercu-regular.eot?#iefix") format("embedded-opentype"),
        url("/assets/centex/fonts/apercu-regular.woff2") format("woff2"),
        url("/assets/centex/fonts/apercu-regular.woff") format("woff"),
        url("/assets/centex/fonts/apercu-regular.ttf") format("truetype"),
        url("/assets/centex/fonts/apercu-regular.svg#apercuregular") format("svg");
    font-weight: 700;
    font-style: italic;
}

/* Bold
 *
 * How to use:
 * .class {
 *      font-weight: 900;
 * }
 */
@font-face {
    font-family: "Apercu";
    src:url("/assets/centex/fonts/apercu-regular.eot");
    src:url("/assets/centex/fonts/apercu-regular.eot?#iefix") format("embedded-opentype"),
        url("/assets/centex/fonts/apercu-regular.woff2") format("woff2"),
        url("/assets/centex/fonts/apercu-regular.woff") format("woff"),
        url("/assets/centex/fonts/apercu-regular.ttf") format("truetype"),
        url("/assets/centex/fonts/apercu-regular.svg#apercuregular") format("svg");
    font-weight: 900;
    font-style: normal;
}

/* Bold Italic
 *
 * How to use:
 * .class {
 *      font-weight: 900;
 *      font-style: italic;
 * }
 */
@font-face {
    font-family: "Apercu";
    src:url("/assets/centex/fonts/apercu-regular.eot");
    src:url("/assets/centex/fonts/apercu-regular.eot?#iefix") format("embedded-opentype"),
        url("/assets/centex/fonts/apercu-regular.woff2") format("woff2"),
        url("/assets/centex/fonts/apercu-regular.woff") format("woff"),
        url("/assets/centex/fonts/apercu-regular.ttf") format("truetype"),
        url("/assets/centex/fonts/apercu-regular.svg#apercuregular") format("svg");
    font-weight: 900;
    font-style: italic;
}
.form-row {
  display: flex;
  flex-direction: row;
}

.form-input {
  border-style: none;
  padding: 5px 10px;
  height: 3em;
}

.form-text-area {
  resize: none;
}

.form-input-error {
  border: 2px solid var(--error-red);
}

.form-checkbox {
  height: 100%;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 0;
  border: 1px solid var(--gray-custom-filter);
  border-radius: 6px;
  cursor: pointer;

  &:checked {
    background-image: var(--brand-checkmark-icon);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    border: 1px solid var(--btn-primary-border);
  }
}

.form-radio-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 15% !important;
  background-clip: content-box;
  border: 1px solid var(--gray-custom-filter);
  border-radius: 50%;
  outline: none;
  cursor: pointer;

  &:checked {
    background-color: var(--brand-toggle-inactive-text);
  }
}

.form-radio-pill {
  &-button {
    border-radius: 25px;
  }

  &-label {
    margin-left: -50%;
    color: #fff;

    &-checked {
      color: #000;
    }
  }

  & .form-radio-button:checked {
    padding: 5% !important;
    background-color: #fff;
  }
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
}

// Google Places Autocomplete Dropdown Input Field Container
.pac-container {
  z-index: 1060;
}

.animated-carousel-transitions .carousel-item {
  display: block;
  z-index: 1;
  position: absolute;
  transform: translate3d(100%, 0px, 0px);
  opacity: 0;
  transition: all 0.5s;
  visibility: hidden;
}

.animated-carousel-transitions .carousel-item.active {
  position: relative;
  transform: translate3d(0px, 0px, 0px);
  visibility: visible;
  opacity: 1;
}

.carousel-item.active .animated-carousel-image-zoom img {
  animation: imageZoom var(--carousel-zoom-animation-duration) infinite linear;
}

.carousel-item.active .carousel-caption .animated-carousel-caption-entrance {
  animation: carouselCaptionEntrance 1s forwards;
}

.carousel-item.active .animated-carousel-cta-pop-in {
  animation: carouselCtaPopIn 1.5s forwards;
}

@keyframes imageZoom {
  0% {
    transform: scale3d(1.3, 1.3, 1.3);
  }
  100% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
}

@keyframes carouselCaptionEntrance {
  0% {
    transform: translate3d(1200px, 0px, 0px)
  }
  50% {
    transform: translate3d(1200px, 0px, 0px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes carouselCtaPopIn {
  0% {
    transform: scale3d(0, 0, 0);
  }

  66% {
    transform: scale3d(0, 0, 0);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
